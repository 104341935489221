import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import styled from 'styled-components';
import { Button, Form as FormBT } from 'react-bootstrap';
import axios from 'axios';
import * as Yup from 'yup';
import { authenticationService } from '../../services/authentication';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const config = {
  headers: {
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCR_KEY,
  },
};
const SignupSchema = Yup.object().shape({
  oib: Yup.string()
    .min(11, 'OIB mora imati najmanje 11 znakova')
    .max(11, 'OIB mora imati najviše 11 znakova')
    .required('OIB je obvezan'),
  nazivTvrtke: Yup.string().required('Naziv tvrtke je obvezan'),
  sjediste: Yup.string().required('Sjedište je obvezno'),
  trgovackiSud: Yup.string().required('Trgovački sud je obvezan'),
  brojRjesenja: Yup.string().required('Broj rješenja je obvezan'),
  datumRjesenja: Yup.date().required('Datum rješenja je obvezan'),
  maticniBroj: Yup.string().required('Matični broj je obvezan'),
  temeljniKapital: Yup.string().required('Temeljni kapital je obvezan'),
  brojUsluga: Yup.number().required('Broj usluga je obvezan'),
  username: Yup.string().required('Korisničko ime je obvezno'),
  password: Yup.string().required('Zaporka je obvezna'),
  email: Yup.string()
    .email('Email mora biti valjan')
    .required('Email je obvezan'),
});
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  input {
    margin-bottom: 10px;
  }
  button {
    width: 150px;
    align-self: center;
  }
`;
const handleGetData = (values, setFieldValue) => {
  axios
    .get(
      `https://sudreg-api.pravosudje.hr/javni/subjekt_detalji?tipIdentifikatora=oib&identifikator=${values.oib}&expand_relations=true`,
      config
    )
    .then(function (response) {
      const {
        sjedista,
        skracene_tvrtke,
        mbs,
        sud_nadlezan,
        temeljni_kapitali,
        predmeti_poslovanja,
        datum_osnivanja,
      } = response.data;
      const adresa = `${sjedista[0].ulica} ${sjedista[0].kucni_broj}, ${sjedista[0].naziv_naselja}`;
      setFieldValue('nazivTvrtke', skracene_tvrtke[0].ime);
      setFieldValue('sjediste', adresa);
      setFieldValue('trgovackiSud', sud_nadlezan.naziv);
      setFieldValue('maticniBroj', mbs);
      setFieldValue('temeljniKapital', temeljni_kapitali[0].iznos);
      setFieldValue('brojUsluga', predmeti_poslovanja.length);
      setFieldValue('datumRjesenja', datum_osnivanja);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
};
const formatCurrency = (value) => {
  return new Intl.NumberFormat('hr-HR', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value);
};
const Register = (props) => {
  return (
    <Formik
      initialValues={{
        password: '',
        email: '',
        oib: '',
        nazivTvrtke: '',
        sjediste: '',
        trgovackiSud: '',
        brojRjesenja: '',
        datumRjesenja: new Date(),
        maticniBroj: '',
        temeljniKapital: '',
        brojUsluga: '',
        username: '',
      }}
      validationSchema={SignupSchema}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        setStatus();
        console.log(values);

        authenticationService.register(values).then(
          (user) => {
            if (user) {
              /*  setRegisterMsg(
                'Registracija uspješna. Bit ćete preusmjereni na stranicu za prijavu.'
              ); */
              console.log(user);
              const { from } = props.location.state || {
                from: { pathname: '/login' },
              };
              setTimeout(() => {
                props.history.push(from);
                setSubmitting(false);
                /*  setRegisterMsg(''); */
              }, 4000);
            }
          },
          (error) => {
            console.log(error);
            setSubmitting(false);
            setStatus(error);
          }
        );
      }}
    >
      {({ values, errors, touched, setFieldValue, handleChange }) => (
        <Form
          style={{
            maxWidth: 500,
            justifyContent: 'center',
            margin: '50px auto',
          }}
        >
          <FormWrapper className="form-group">
            <Field
              name="oib"
              id="oib"
              label="oib"
              value={values.oib}
              onChange={handleChange}
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>OIB</FormBT.Label>
                  <FormBT.Control
                    placeholder="Unesite OIB kako bi dohvatili podatke"
                    {...field}
                    type="number"
                  />
                </>
              )}
            </Field>
            <p style={{ color: 'red', textAlign: 'center' }}>
              <ErrorMessage name="oib" />
            </p>
            <Button
              type="button"
              style={{
                backgroundColor: 'rgb(56, 161, 105)',
                borderColor: 'rgb(56, 161, 105)',
              }}
              onClick={() => handleGetData(values, setFieldValue)}
            >
              Dohvati podatke
            </Button>
          </FormWrapper>
          <FormWrapper className="form-group">
            <Field
              name="nazivTvrtke"
              id="nazivTvrtke"
              value={values.nazivTvrtke}
              onChange={handleChange}
              placeholder=""
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>Naziv tvrtke</FormBT.Label>
                  <FormBT.Control placeholder="" {...field} type="text" />
                </>
              )}
            </Field>
            <p style={{ color: 'red', textAlign: 'center' }}>
              <ErrorMessage name="nazivTvrtke" />
            </p>
          </FormWrapper>
          <FormWrapper className="form-group">
            <Field
              name="sjediste"
              id="sjediste"
              value={values.sjediste}
              onChange={handleChange}
              placeholder=""
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>Sjedište</FormBT.Label>
                  <FormBT.Control placeholder="" {...field} type="text" />
                </>
              )}
            </Field>
            <p style={{ color: 'red', textAlign: 'center' }}>
              <ErrorMessage name="sjediste" />
            </p>
          </FormWrapper>

          <FormWrapper className="form-group">
            <Field
              name="trgovackiSud"
              id="trgovackiSud"
              value={values.trgovackiSud}
              onChange={handleChange}
              placeholder=""
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>Trgovački sud</FormBT.Label>
                  <FormBT.Control placeholder="" {...field} type="text" />
                </>
              )}
            </Field>
            <p style={{ color: 'red', textAlign: 'center' }}>
              <ErrorMessage name="trgovackiSud" />
            </p>
          </FormWrapper>
          <FormWrapper className="form-group">
            <Field
              name="brojRjesenja"
              id="brojRjesenja"
              value={values.brojRjesenja}
              onChange={handleChange}
              placeholder=""
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>Broj rješenja</FormBT.Label>
                  <FormBT.Control placeholder="" {...field} type="text" />
                </>
              )}
            </Field>
            <p style={{ color: 'red', textAlign: 'center' }}>
              <ErrorMessage name="brojRjesenja" />
            </p>
          </FormWrapper>
          <FormWrapper className="form-group">
            <Field
              name="datumRjesenja"
              id="datumRjesenja"
              value={values.datumRjesenja}
              onChange={(value) => {
                setFieldValue('datumRjesenja', value);
              }}
              placeholder=""
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>Datum rješenja</FormBT.Label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      {...field}
                      ampm={false}
                      format="dd.MM.yyyy HH:mm"
                      inputVariant="outlined"
                      onChange={(value) => {
                        setFieldValue('datumRjesenja', value);
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {/* <FormBT.Control placeholder="" {...field} type="text" /> */}
                </>
              )}
            </Field>
            <p style={{ color: 'red', textAlign: 'center' }}>
              <ErrorMessage name="datumRjesenja" />
            </p>
          </FormWrapper>
          <FormWrapper className="form-group">
            <Field
              name="maticniBroj"
              id="maticniBroj"
              value={values.maticniBroj}
              onChange={handleChange}
              placeholder=""
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>Matični broj</FormBT.Label>
                  <FormBT.Control placeholder="" {...field} type="number" />
                </>
              )}
            </Field>
            <p style={{ color: 'red', textAlign: 'center' }}>
              <ErrorMessage name="maticniBroj" />
            </p>
          </FormWrapper>

          <FormWrapper className="form-group">
            <Field name="temeljniKapital" id="temeljniKapital" placeholder="">
              {({ field, form }) => (
                <>
                  <FormBT.Label>Temeljni kapital</FormBT.Label>
                  <FormBT.Control
                    value={values.temeljniKapital}
                    onChange={handleChange}
                    placeholder=""
                    {...field}
                    type="text"
                  />
                </>
              )}
            </Field>
            <p style={{ color: 'red', textAlign: 'center' }}>
              <ErrorMessage name="temeljniKapital" />
            </p>
          </FormWrapper>
          <FormWrapper className="form-group">
            <Field
              name="brojUsluga"
              id="brojUsluga"
              value={values.brojUsluga}
              onChange={handleChange}
              placeholder=""
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>Broj usluga</FormBT.Label>
                  <FormBT.Control placeholder="" {...field} type="number" />
                </>
              )}
            </Field>
            <p style={{ color: 'red', textAlign: 'center' }}>
              <ErrorMessage name="brojUsluga" />
            </p>
          </FormWrapper>
          <FormWrapper className="form-group">
            <Field
              name="username"
              id="username"
              value={values.username}
              onChange={handleChange}
              placeholder=""
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>Korisničko ime</FormBT.Label>
                  <FormBT.Control placeholder="" {...field} type="text" />
                </>
              )}
            </Field>
            <p style={{ color: 'red', textAlign: 'center' }}>
              <ErrorMessage name="username" />
            </p>
          </FormWrapper>
          <FormWrapper className="form-group">
            <Field
              name="password"
              id="password"
              value={values.password}
              onChange={handleChange}
              placeholder=""
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>Zaporka</FormBT.Label>
                  <FormBT.Control placeholder="" {...field} type="password" />
                </>
              )}
            </Field>
            <p style={{ color: 'red', textAlign: 'center' }}>
              <ErrorMessage name="password" />
            </p>
          </FormWrapper>
          <FormWrapper className="form-group">
            <Field
              name="email"
              id="email"
              value={values.email}
              onChange={handleChange}
              placeholder=""
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>Email</FormBT.Label>
                  <FormBT.Control placeholder="" {...field} type="email" />
                </>
              )}
            </Field>
            <p style={{ color: 'red', textAlign: 'center' }}>
              <ErrorMessage name="email" />
            </p>
          </FormWrapper>
          {/* add username, password, email */}
          <div align="center">
            <Button
              style={{
                backgroundColor: 'rgb(56, 161, 105)',
                borderColor: 'rgb(56, 161, 105)',
              }}
              type="submit"
            >
              Registriraj se
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Register;
