import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import './App.module.scss';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { authenticationService } from '../../services/authentication';
import { history } from '../../helpers/history';
import { PrivateRoute } from '../PrivateRoute/PrivateRoute';
import Register from '../Register/Register';
import Login from '../Login/Login';
import Home from '../Home/Home';

const Header = styled.header`
  display: flex;
  height: 100px;
  background-color: #30336b;
  margin-bottom: 15px;
  div {
    align-self: center;
    color: white;
  }
`;
function logout() {
  authenticationService.logout();
  history.push('/login');
}
function App() {
  const [currentUser, setCurrentUser] = React.useState(null);
  const [isLoading, setLoading] = React.useState(true);
  React.useEffect(() => {
    authenticationService.currentUser.subscribe(x => setCurrentUser(x));
    setTimeout(() => {
      setLoading(false);
    }, 350);
  });

  return (
    <Router history={history}>
      <Header>
        <Container>
          <h2>Knjiga poslovnih udjela</h2>
        </Container>
      </Header>
      <Container>
        <Row style={{ justifyContent: 'flex-end' }}>
          <Col
            sm={3}
            align="end"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {currentUser ? (
              <>
                <Link to="/">
                  <Button
                    style={{
                      backgroundColor: 'rgb(47, 133, 90)',
                      borderColor: 'rgb(47, 133, 90)'
                    }}
                  >
                    Početna
                  </Button>
                </Link>

                <Button
                  onClick={() => {
                    logout();
                  }}
                  style={{
                    backgroundColor: 'rgb(47, 133, 90)',
                    borderColor: 'rgb(47, 133, 90)'
                  }}
                >
                  Odjava
                </Button>
              </>
            ) : (
              <>
                <Link to="/login">
                  <Button
                    style={{
                      backgroundColor: 'rgb(47, 133, 90)',
                      borderColor: 'rgb(47, 133, 90)'
                    }}
                  >
                    Prijava
                  </Button>
                </Link>
                <Link to="/register">
                  <Button
                    style={{
                      backgroundColor: 'rgb(47, 133, 90)',
                      borderColor: 'rgb(47, 133, 90)'
                    }}
                  >
                    Registracija
                  </Button>
                </Link>
              </>
            )}
          </Col>
        </Row>
      </Container>

      <Container>
        <Switch>
          <PrivateRoute exact path="/">
            <Home currentUser={currentUser}  />
          </PrivateRoute>
          <Route path="/login" component={Login}></Route>
          <Route path="/register" component={Register}></Route>
        </Switch>
      </Container>
    </Router>
  );
}

export default App;
