import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { authenticationService } from '../../services/authentication';

const LoginPage = (props) => {
  // redirect to home if already logged in
  /*  React.useEffect(() => {
  }); */
  function pushToHistory(from) {
    props.history.push(from);
  }
  if (authenticationService.currentUserValue) {
    pushToHistory('/');
  }

  return (
    <div className={`container`}>
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().required('Korisničko ime je obvezno'),
          password: Yup.string().required('Zaporka je obvezna'),
        })}
        onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
          authenticationService.login(username, password).then(
            (res) => {
              if (res.id) {
                const { from } = props.location.state || {
                  from: { pathname: '/' },
                };
                console.log(res);
                pushToHistory(from);
              }
              setStatus(res);
              setTimeout(() => {
                setStatus(false);
                setSubmitting(false);
              }, 2000);
            },
            (error) => {
              setSubmitting(false);
            }
          );
        }}
        render={({ errors, status, touched, isSubmitting }) => (
          <Form style={{ maxWidth: 600, margin: 'auto', marginTop: 50 }}>
            {/* {props.sessionMessage.length > 1 ? (
              <p style={{ textAlign: 'center', color: 'red' }}>
                {props.sessionMessage}
              </p>
            ) : null} */}
            <div className={`form-group`}>
              <label htmlFor="username">Korisničko ime</label>
              <Field
                name="username"
                type="text"
                className={
                  'form-control' +
                  (errors.username && touched.username ? ' is-invalid' : '')
                }
              />
              <ErrorMessage
                name="username"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className={`form-group`}>
              <label htmlFor="password">Lozinka</label>
              <Field
                name="password"
                type="password"
                className={
                  'form-control' +
                  (errors.password && touched.password ? ' is-invalid' : '')
                }
              />
              <ErrorMessage
                name="password"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  backgroundColor: 'rgb(56, 161, 105)',
                  borderColor: 'rgb(56, 161, 105)',
                }}
                disabled={isSubmitting}
              >
                Prijava
              </button>
              {
                /* isSubmitting &&
                 */
                //loader here
              }
            </div>
            {status && <div className={'alert alert-danger'}>{status}</div>}
          </Form>
        )}
      />
    </div>
  );
};

export default LoginPage;
