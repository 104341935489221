import React from 'react';
import {
  Button,
  Dropdown,
  DropdownButton,
  Row,
  Col,
  Form as FormBT,
} from 'react-bootstrap';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import styled from 'styled-components';
import * as Yup from 'yup';

const SignupSchema = Yup.object().shape({
  datumNew: Yup.date().required('Datum upisa je obvezan'),
  temeljniUlogNew: Yup.string().required('Iznos temeljnog uloga je obvezan'),
  uplacenoNew: Yup.string().required('Uplaćeno je obvezno polje'),
  dodatneCinidbeNew: Yup.string().required('Dodatne činidbe su obvezno polje'),
  podaciOsnivacaNew: Yup.string().required(
    'Novi član društva je obvezno polje'
  ),
  teretiNaUdjeluNew: Yup.string().required(
    'Tereti na poslovnom udjelu su obvezni'
  ),
  brojGlasovaNew: Yup.number().required('Broj glasova je obvezan'),
});
const ErrorMsg = styled.p`
  color: red;
`;
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  input {
    margin-bottom: 10px;
  }
  button {
    outline: 0;
    width: 150px;
    align-self: center;
    &.dropdown-toggle {
      /* max-width: 300px; */
      width: 100%;
      margin-bottom: 10px;
    }
  }
  input[name='datum'] {
    padding: 10px;
    margin-bottom: 0 !important;
  }
  input[name='datumNew'] {
    padding: 10px;
    margin-bottom: 0 !important;
  }
`;
function formatCurrency(number) {
  return new Intl.NumberFormat('hr-HR', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(number);
}
function CreateValues(
  redniBroj,
  datum,
  temeljniUlog,
  uplaceno,
  dodatneCinidbe,
  podaciOsnivaca,
  teretiNaUdjelu,
  brojGlasova,
  user
) {
  let object = {
    redniBroj,
    datum,
    temeljniUlog,
    uplaceno,
    dodatneCinidbe,
    podaciOsnivaca,
    teretiNaUdjelu,
    brojGlasova,
    user,
  };
  return Object.assign(object);
}

const NewShareModal = ({ currentUser, data, setEditShareModal, setFlag }) => {
  const getMember = (name) => {
    const obj = data.find((obj) => obj.podaciOsnivaca === name);
    return obj;
  };
  return (
    <Formik
      initialValues={{
        deleteId: 0,
        redniBroj: '',
        datum: new Date(),
        temeljniUlog: '',
        uplaceno: '',
        dodatneCinidbe: '',
        podaciOsnivaca: 'Izaberite člana',
        teretiNaUdjelu: '',
        brojGlasova: '',
        redniBrojNew: '',
        datumNew: new Date(),
        temeljniUlogNew: '',
        uplacenoNew: '',
        dodatneCinidbeNew: '',
        podaciOsnivacaNew: '',
        teretiNaUdjeluNew: '',
        brojGlasovaNew: '',
      }}
      validationSchema={SignupSchema}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        const {
          datum,
          temeljniUlog,
          uplaceno,
          dodatneCinidbe,
          podaciOsnivaca,
          teretiNaUdjelu,
          brojGlasova,
          datumNew,
          temeljniUlogNew,
          uplacenoNew,
          dodatneCinidbeNew,
          podaciOsnivacaNew,
          teretiNaUdjeluNew,
          brojGlasovaNew,
        } = values;
        const newValues = [];
        temeljniUlog - temeljniUlogNew > 0 &&
          newValues.push(
            new CreateValues(
              data[data.length - 1].redniBroj + 1,
              datum,
              temeljniUlog - temeljniUlogNew,
              uplaceno - uplacenoNew,
              dodatneCinidbe,
              podaciOsnivaca,
              teretiNaUdjelu,
              brojGlasova - brojGlasovaNew,
              currentUser.id
            )
          );
        newValues.push(
          new CreateValues(
            temeljniUlog - temeljniUlogNew > 0
              ? data[data.length - 1].redniBroj + 2
              : data[data.length - 1].redniBroj + 1,
            datumNew,
            temeljniUlogNew,
            uplacenoNew,
            dodatneCinidbeNew,
            podaciOsnivacaNew,
            teretiNaUdjeluNew,
            brojGlasovaNew,
            currentUser.id
          )
        );

        console.log(newValues);
        axios
          .post(
            `${process.env.REACT_APP_HOST_URL}/shares/changes`,
            {
              values: newValues,
              id: values.deleteId,
              newOsnivac: podaciOsnivacaNew,
            },
            {
              headers: { Authorization: `JWT ${currentUser.token}` },
            }
          )
          .then(function (response) {
            setStatus(response.data.status);

            setSubmitting(false);
            // resetForm();
            setFlag(Math.random());
            // setEditShareModal(false);

            //set flag invoice saved, settimeout 2s and rerender
          })
          .catch(function (error) {
            console.log(error);
          });
      }}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <Row>
            <Col>
              <FormWrapper className="form-group">
                <Field
                  name="oib"
                  id="oib"
                  label="oib"
                  value={values.oib}
                  onChange={handleChange}
                >
                  {({ field, form }) => (
                    <>
                      <FormBT.Label>Odabir člana za promjenu</FormBT.Label>
                      <DropdownButton
                        variant="outline-secondary"
                        title={values.podaciOsnivaca}
                        id="input-group-dropdown-1"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                        {...field}
                      >
                        <Dropdown.Item
                          onClick={() =>
                            setFieldValue('podaciOsnivaca', 'Izaberite člana')
                          }
                        >
                          Izaberite člana
                        </Dropdown.Item>
                        {data.map((item) => {
                          return (
                            <Dropdown.Item
                              key={item.podaciOsnivaca}
                              onClick={() => {
                                setFieldValue(
                                  'podaciOsnivaca',
                                  item.podaciOsnivaca
                                );
                                const member = getMember(item.podaciOsnivaca);
                                console.log(member);
                                setFieldValue('datum', member.datum);
                                setFieldValue(
                                  'temeljniUlog',
                                  Number(member.temeljniUlog)
                                );
                                setFieldValue(
                                  'uplaceno',
                                  Number(member.uplaceno)
                                );
                                setFieldValue(
                                  'dodatneCinidbe',
                                  member.dodatneCinidbe
                                );
                                setFieldValue(
                                  'teretiNaUdjelu',
                                  member.teretiNaUdjelu
                                );
                                setFieldValue(
                                  'brojGlasova',
                                  member.brojGlasova
                                );
                                setFieldValue('deleteId', member.id);
                              }}
                            >
                              {item.podaciOsnivaca}
                            </Dropdown.Item>
                          );
                        })}
                      </DropdownButton>
                    </>
                  )}
                </Field>
              </FormWrapper>
              <FormWrapper className="form-group">
                <Field
                  name="datum"
                  id="datum"
                  label="datum"
                  value={values.datum}
                  onChange={handleChange}
                >
                  {({ field, form }) => (
                    <>
                      <FormBT.Label>Datum rješenja</FormBT.Label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          {...field}
                          ampm={false}
                          format="dd.MM.yyyy"
                          inputVariant="outlined"
                          readOnly
                          onChange={(value) => {
                            setFieldValue('datum', value);
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </>
                  )}
                </Field>
                <ErrorMessage name="datum" />
              </FormWrapper>
              <FormWrapper className="form-group">
                <Field name="temeljniUlog" id="temeljniUlog">
                  {({ field, form }) => (
                    <>
                      <FormBT.Label>Temeljni ulog</FormBT.Label>
                      <FormBT.Control
                        {...field}
                        readOnly
                        placeholder=""
                        type="text"
                        value={formatCurrency(values.temeljniUlog)}
                      />
                    </>
                  )}
                </Field>
                <ErrorMessage name="temeljniUlog" />
              </FormWrapper>
              <FormWrapper className="form-group">
                <Field name="uplaceno" id="uplaceno">
                  {({ field, form }) => (
                    <>
                      <FormBT.Label>Uplaćeno</FormBT.Label>
                      <FormBT.Control
                        {...field}
                        readOnly
                        value={formatCurrency(values.uplaceno)}
                        placeholder=""
                        type="text"
                      />
                    </>
                  )}
                </Field>
                <ErrorMessage name="uplaceno" />
              </FormWrapper>
              <FormWrapper className="form-group">
                <Field
                  name="dodatneCinidbe"
                  id="dodatneCinidbe"
                  label="dodatneCinidbe"
                  value={values.dodatneCinidbe}
                  onChange={handleChange}
                >
                  {({ field, form }) => (
                    <>
                      <FormBT.Label>Dodatne činidbe</FormBT.Label>
                      <FormBT.Control
                        readOnly
                        placeholder=""
                        {...field}
                        type="text"
                      />
                    </>
                  )}
                </Field>
                <ErrorMessage name="dodatneCinidbe" />
              </FormWrapper>
              <FormWrapper className="form-group">
                <Field
                  name="teretiNaUdjelu"
                  id="teretiNaUdjelu"
                  label="teretiNaUdjelu"
                  value={values.teretiNaUdjelu}
                  onChange={handleChange}
                >
                  {({ field, form }) => (
                    <>
                      <FormBT.Label>Tereti...</FormBT.Label>
                      <FormBT.Control
                        readOnly
                        placeholder=""
                        {...field}
                        type="text"
                      />
                    </>
                  )}
                </Field>
                <ErrorMessage name="teretiNaUdjelu" />
              </FormWrapper>
              <FormWrapper className="form-group">
                <Field
                  name="brojGlasova"
                  id="brojGlasova"
                  label="brojGlasova"
                  value={values.brojGlasova}
                  onChange={handleChange}
                >
                  {({ field, form }) => (
                    <>
                      <FormBT.Label>Broj glasova</FormBT.Label>
                      <FormBT.Control
                        readOnly
                        placeholder=""
                        {...field}
                        type="number"
                      />
                    </>
                  )}
                </Field>
                <ErrorMessage name="brojGlasova" />
              </FormWrapper>
            </Col>
            <Col>
              <FormWrapper className="form-group">
                <Field
                  name="podaciOsnivacaNew"
                  id="podaciOsnivacaNew"
                  label="podaciOsnivacaNew"
                  value={values.podaciOsnivacaNew}
                  onChange={handleChange}
                >
                  {({ field, form }) => (
                    <>
                      <FormBT.Label>Novi član društva</FormBT.Label>
                      <FormBT.Control placeholder="" {...field} type="text" />
                    </>
                  )}
                </Field>
                <ErrorMessage component={ErrorMsg} name="podaciOsnivacaNew" />
              </FormWrapper>
              <FormWrapper className="form-group">
                <Field
                  name="datumNew"
                  id="datumNew"
                  label="datumNew"
                  value={values.datumNew}
                  onChange={handleChange}
                >
                  {({ field, form }) => (
                    <>
                      <FormBT.Label>Datum rješenja</FormBT.Label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          {...field}
                          ampm={false}
                          format="dd.MM.yyyy"
                          inputVariant="outlined"
                          onChange={(value) => {
                            setFieldValue('datumNew', value);
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </>
                  )}
                </Field>
                <ErrorMessage component={ErrorMsg} name="datum" />
              </FormWrapper>
              <FormWrapper className="form-group">
                <Field
                  name="temeljniUlogNew"
                  id="temeljniUlogNew"
                  label="temeljniUlogNew"
                  value={values.temeljniUlogNew}
                  onChange={handleChange}
                >
                  {({ field, form }) => (
                    <>
                      <FormBT.Label>Temeljni ulog</FormBT.Label>
                      <FormBT.Control placeholder="" {...field} type="number" />
                    </>
                  )}
                </Field>
                <ErrorMessage component={ErrorMsg} name="temeljniUlogNew" />
              </FormWrapper>
              <FormWrapper className="form-group">
                <Field
                  name="uplacenoNew"
                  id="uplacenoNew"
                  label="uplacenoNew"
                  value={values.uplacenoNew}
                  onChange={handleChange}
                >
                  {({ field, form }) => (
                    <>
                      <FormBT.Label>Uplaćeno</FormBT.Label>
                      <FormBT.Control placeholder="" {...field} type="number" />
                    </>
                  )}
                </Field>
                <ErrorMessage component={ErrorMsg} name="uplacenoNew" />
              </FormWrapper>
              <FormWrapper className="form-group">
                <Field
                  name="dodatneCinidbeNew"
                  id="dodatneCinidbeNew"
                  label="dodatneCinidbeNew"
                  value={values.dodatneCinidbeNew}
                  onChange={handleChange}
                >
                  {({ field, form }) => (
                    <>
                      <FormBT.Label>Dodatne činidbe</FormBT.Label>
                      <FormBT.Control placeholder="" {...field} type="text" />
                    </>
                  )}
                </Field>
                <ErrorMessage component={ErrorMsg} name="dodatneCinidbeNew" />
              </FormWrapper>
              <FormWrapper className="form-group">
                <Field
                  name="teretiNaUdjeluNew"
                  id="teretiNaUdjeluNew"
                  label="teretiNaUdjeluNew"
                  value={values.teretiNaUdjeluNew}
                  onChange={handleChange}
                >
                  {({ field, form }) => (
                    <>
                      <FormBT.Label>Tereti...</FormBT.Label>
                      <FormBT.Control placeholder="" {...field} type="text" />
                    </>
                  )}
                </Field>
                <ErrorMessage component={ErrorMsg} name="teretiNaUdjeluNew" />
              </FormWrapper>
              <FormWrapper className="form-group">
                <Field
                  name="brojGlasovaNew"
                  id="brojGlasovaNew"
                  label="brojGlasovaNew"
                  value={values.brojGlasovaNew}
                  onChange={handleChange}
                >
                  {({ field, form }) => (
                    <>
                      <FormBT.Label>Broj glasova</FormBT.Label>
                      <FormBT.Control placeholder="" {...field} type="number" />
                    </>
                  )}
                </Field>
                <ErrorMessage component={ErrorMsg} name="brojGlasovaNew" />
              </FormWrapper>
              <Button
                variant="primary"
                type="submit"
                style={{
                  backgroundColor: 'rgb(56, 161, 105)',
                  borderColor: 'rgb(56, 161, 105)',
                }}
                onClick={() => setEditShareModal(false)}
              >
                Spremi
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default NewShareModal;
