export const formatDate = date => {
  const monthNames = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12"
  ];
  let newDate = new Date(date);
  const day = newDate.getDate();
  const monthIndex = newDate.getMonth();
  const year = newDate.getFullYear();
  const correctDay = day < 10 ? "0" + day : day;
  return correctDay + "." + monthNames[monthIndex] + "." + year;
};