import React from 'react';
import { Button, Form as FormBT } from 'react-bootstrap';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import styled from 'styled-components';
import * as Yup from 'yup';

const SignupSchema = Yup.object().shape({
  datum: Yup.date().required('Datum upisa je obvezan'),
  temeljniUlog: Yup.string().required('Iznos temeljnog uloga je obvezan'),
  uplaceno: Yup.string().required('Uplaćeno je obvezno polje'),
  dodatneCinidbe: Yup.date().required('Dodatne činidbe su obvezno polje'),
  podaciOsnivaca: Yup.string().required(
    'Osnivač - član društva je obvezno polje'
  ),
  teretiNaUdjelu: Yup.string().required(
    'Tereti na poslovnom udjelu su obvezni'
  ),
  brojGlasova: Yup.number().required('Broj glasova je obvezan'),
});
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  input {
    margin-bottom: 10px;
  }
  button {
    outline: 0;
    width: 150px;
    align-self: center;
    &.dropdown-toggle {
      max-width: 300px;
      width: 100%;
    }
  }
  input[name='datum'] {
    padding: 10px;
    margin-bottom: 0 !important;
  }
`;
const ErrorMsg = styled.p`
  color: red;
`;
const NewShareModal = ({
  setFlag,
  setAddNewModal,
  currentUser,
  nextNumber,
}) => {
  return (
    <Formik
      initialValues={{
        redniBroj: nextNumber,
        datum: new Date(),
        temeljniUlog: '',
        uplaceno: '',
        dodatneCinidbe: '',
        podaciOsnivaca: '',
        teretiNaUdjelu: '',
        brojGlasova: '',
      }}
      validationSchema={SignupSchema}
      onSubmit={(values, { setStatus, setSubmitting, resetForm }) => {
        axios
          .post(`${process.env.REACT_APP_HOST_URL}/shares/add`, values, {
            headers: { Authorization: `JWT ${currentUser.token}` },
          })
          .then(function (response) {
            setStatus(response.data.status);

            setSubmitting(false);
            // resetForm();
            setFlag(Math.random());
            setAddNewModal(false);

            //set flag invoice saved, settimeout 2s and rerender
          })
          .catch(function (error) {
            console.log(error);
          });
      }}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <FormWrapper className="form-group">
            <Field
              name="redniBroj"
              id="redniBroj"
              label="redniBroj"
              value={values.redniBroj}
              readOnly
              onChange={handleChange}
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>Redni broj upisa</FormBT.Label>
                  <FormBT.Control
                    placeholder=""
                    {...field}
                    readOnly
                    type="number"
                  />
                </>
              )}
            </Field>
            <ErrorMessage component={ErrorMsg} name="redniBroj" />
          </FormWrapper>
          <FormWrapper className="form-group">
            <Field
              name="podaciOsnivaca"
              id="podaciOsnivaca"
              label="podaciOsnivaca"
              value={values.podaciOsnivaca}
              onChange={handleChange}
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>Osnivač - član društva</FormBT.Label>
                  <FormBT.Control placeholder="" {...field} type="text" />
                </>
              )}
            </Field>
            <ErrorMessage component={ErrorMsg} name="podaciOsnivaca" />
          </FormWrapper>
          <FormWrapper className="form-group">
            <Field
              name="datum"
              id="datum"
              label="datum"
              value={values.datum}
              onChange={handleChange}
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>Datum upisa</FormBT.Label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      {...field}
                      ampm={false}
                      format="dd.MM.yyyy HH:mm"
                      inputVariant="outlined"
                      onChange={(value) => {
                        setFieldValue('datum', value);
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </>
              )}
            </Field>
            <ErrorMessage component={ErrorMsg} name="datum" />
          </FormWrapper>
          <FormWrapper className="form-group">
            <Field
              name="temeljniUlog"
              id="temeljniUlog"
              label="temeljniUlog"
              value={values.temeljniUlog}
              onChange={handleChange}
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>Iznos temeljnog uloga</FormBT.Label>
                  <FormBT.Control placeholder="" {...field} type="number" />
                </>
              )}
            </Field>
            <ErrorMessage component={ErrorMsg} name="temeljniUlog" />
          </FormWrapper>
          <FormWrapper className="form-group">
            <Field
              name="uplaceno"
              id="uplaceno"
              label="uplaceno"
              value={values.uplaceno}
              onChange={handleChange}
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>Uplaćeno</FormBT.Label>
                  <FormBT.Control placeholder="" {...field} type="number" />
                </>
              )}
            </Field>
            <ErrorMessage component={ErrorMsg} name="uplaceno" />
          </FormWrapper>
          <FormWrapper className="form-group">
            <Field
              name="dodatneCinidbe"
              id="dodatneCinidbe"
              label="dodatneCinidbe"
              value={values.dodatneCinidbe}
              onChange={handleChange}
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>
                    Dodatne činidbe koje je član dužan ispuniti
                  </FormBT.Label>
                  <FormBT.Control placeholder="" {...field} type="text" />
                </>
              )}
            </Field>
            <ErrorMessage component={ErrorMsg} name="dodatneCinidbe" />
          </FormWrapper>
          <FormWrapper className="form-group">
            <Field
              name="teretiNaUdjelu"
              id="teretiNaUdjelu"
              label="teretiNaUdjelu"
              value={values.teretiNaUdjelu}
              onChange={handleChange}
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>Tereti na poslovnom udjelu</FormBT.Label>
                  <FormBT.Control placeholder="" {...field} type="text" />
                </>
              )}
            </Field>
            <ErrorMessage component={ErrorMsg} name="teretiNaUdjelu" />
          </FormWrapper>
          <FormWrapper className="form-group">
            <Field
              name="brojGlasova"
              id="brojGlasova"
              label="brojGlasova"
              value={values.brojGlasova}
              onChange={handleChange}
            >
              {({ field, form }) => (
                <>
                  <FormBT.Label>Broj glasova</FormBT.Label>
                  <FormBT.Control placeholder="" {...field} type="number" />
                </>
              )}
            </Field>
            <ErrorMessage component={ErrorMsg} name="brojGlasova" />
          </FormWrapper>
          <Button
            variant="primary"
            type="submit"
            style={{
              backgroundColor: 'rgb(56, 161, 105)',
              borderColor: 'rgb(56, 161, 105)',
            }}
            onClick={() => setAddNewModal(false)}
          >
            Spremi
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default NewShareModal;
