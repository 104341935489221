import React from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import axios from 'axios';
import { formatDate } from '../../helpers/tools';
import NewShareModal from '../NewShareModal';
import EditShareModal from '../EditShareModal';
import Excel from 'exceljs';
import saveAs from 'file-saver';

const columns = [
  {
    dataField: 'redniBroj',
    text: 'Redni broj',
  },
  {
    dataField: 'datum',
    text: 'Datum',
    formatter: (cell, row, rowIndex) => {
      return formatDate(cell);
    },
  },
  {
    dataField: 'temeljniUlog',
    text: 'Iznos temeljnog uloga',
  },
  {
    dataField: 'uplaceno',
    text: 'Uplaćeno',
    sort: true,
  },
  {
    dataField: 'dodatneCinidbe',
    text: 'Dodatne činidbe',
  },
  {
    dataField: 'podaciOsnivaca',
    text: 'Podaci o osnivaču - članu društva',
  },
  {
    dataField: 'teretiNaUdjelu',
    text: 'Tereti na poslovnom udjelu',
  },
  {
    dataField: 'brojGlasova',
    text: 'Broj glasova',
  },
];
const cols = [
  { header: 'Redni broj upisa', key: 'redniBroj', width: 15 },
  { header: 'Datum upisa', key: 'datum', width: 15 },
  {
    header: 'Iznos temeljnog uloga - poslovnog udjela kojeg je član preuzeo',
    key: 'temeljniUlog',
    width: 15,
  },
  { header: 'Uplaćeno', key: 'uplaceno', width: 15 },
  {
    header: 'Dodatne činidbe koje je član dužan ispuniti ',
    key: 'dodatneCinidbe',
    width: 15,
  },
  {
    header: 'Podaci o osnivaču - članu društva ',
    key: 'podaciOsnivaca',
    width: 15,
  },
  { header: 'Tereti na poslovnom udjelu', key: 'teretiNaUdjelu', width: 15 },
  { header: 'Broj glasova', key: 'brojGlasova', width: 15 },
];
const addNewPage = (wb, cols, data) => {
  const ws = wb.addWorksheet(String(data.redniBroj));
  ws.columns = cols;
  ws.getRow(1).font = { bold: true };
  ws.addRow({
    id: data.id,
    redniBroj: data.redniBroj,
    datum: data.datum,
    temeljniUlog: data.temeljniUlog,
    uplaceno: data.uplaceno,
    dodatneCinidbe: data.dodatneCinidbe,
    podaciOsnivaca: data.podaciOsnivaca,
    teretiNaUdjelu: data.teretiNaUdjelu,
    brojGlasova: data.brojGlasova,
  });
  return wb;
};
async function saveAsExcel(cols, data) {
  const wb = new Excel.Workbook();
  data.map((item) => {
    return addNewPage(wb, cols, item);
  });
  const buf = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buf]), 'knjiga-poslovnih-udjela.xlsx');
}
const Home = ({ currentUser }) => {
  const [data, setData] = React.useState([]);
  const [addNewModal, setAddNewModal] = React.useState(false);
  const [editShareModal, setEditShareModal] = React.useState(false);
  const [nextNumber, setNextNumber] = React.useState(0);
  const [flag, setFlag] = React.useState(0);
  console.log(data, currentUser);
  React.useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `${process.env.REACT_APP_HOST_URL}/shares/get-all/?userId=${currentUser.id}`,
        {
          headers: { Authorization: `JWT ${currentUser.token}` },
        }
      );
      setData(
        result.data.length < 1
          ? []
          : result.data.map((item) => {
              return {
                id: item.id,
                redniBroj: item.redniBroj,
                datum: item.datum,
                temeljniUlog: item.temeljniUlog,
                uplaceno: item.uplaceno,
                dodatneCinidbe: item.dodatneCinidbe,
                podaciOsnivaca: item.podaciOsnivaca,
                teretiNaUdjelu: item.teretiNaUdjelu,
                brojGlasova: item.brojGlasova,
              };
            })
      );
      setNextNumber(
        result.data.length < 1
          ? 1
          : result.data[result.data.length - 1].redniBroj + 1
      );
    };
    currentUser && fetchData();
  }, [currentUser, flag]);
  return (
    <div>
      <Row>
        <Col
          sm={4}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button
            style={{
              backgroundColor: 'rgb(56, 161, 105)',
              borderColor: 'rgb(56, 161, 105)',
            }}
            onClick={() => setAddNewModal(true)}
          >
            Novi unos
          </Button>
          <Button
            style={{
              backgroundColor: 'rgb(56, 161, 105)',
              borderColor: 'rgb(56, 161, 105)',
            }}
            onClick={() => setEditShareModal(true)}
          >
            Promjena unosa
          </Button>
          <Button
            style={{
              backgroundColor: 'rgb(56, 161, 105)',
              borderColor: 'rgb(56, 161, 105)',
            }}
            onClick={() => saveAsExcel(cols, data)}
          >
            xlsx
          </Button>
        </Col>
      </Row>
      <div style={{ marginTop: 50 }}>
        <BootstrapTable
          keyField="id"
          bootstrap4
          data={data}
          columns={columns}
          defaultSorted={[{ dataField: 'uplaceno', order: 'asc' }]}
        />
      </div>
      <Modal show={addNewModal} onHide={() => setAddNewModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Novi unos </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewShareModal
            data={data}
            setAddNewModal={() => setAddNewModal}
            currentUser={currentUser}
            nextNumber={nextNumber}
            setFlag={setFlag}
          ></NewShareModal>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setAddNewModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        show={editShareModal}
        onHide={() => setEditShareModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Novi unos </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditShareModal
            data={data}
            setEditShareModal={() => setEditShareModal}
            currentUser={currentUser}
            nextNumber={nextNumber}
            setFlag={setFlag}
          ></EditShareModal>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditShareModal(false)}>
            Odustani
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Home;
